import { useMemo } from "react";
import styled, { css } from "styled-components";
import { chains as assets } from "@sablier/v2-assets";
import TokenUnknown from "@sablier/v2-assets/tokens/custom-unknown-light.svg";
import { chains } from "@sablier/v2-constants";
import { _ } from "@sablier/v2-mixins";
import { IImage } from "@sablier/v2-types";
import type { ThemeType } from "@sablier/v2-themes";
import Icon from "../Icon";

interface WrapperProps {
  sizeIcon?: number | string;
  sizeName?: number | string;
  color?: string;
}

const WrapperPartial = styled.div<WrapperProps>`
  ${(props) => props.theme.styles.row}
  & {
    height: ${(props) => props.sizeIcon};
    column-gap: ${(props) =>
      props.sizeIcon && parseInt(props.sizeIcon + "", 10) < 26 ? "4px" : "6px"};
  }
`;

const Name = styled.div`
  & > p {
    ${(props) => props.theme.styles.textElement}
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${Name} > p {
    ${(props) =>
      props.color &&
      css`
        color: ${props.color};
      `}

    ${(props) =>
      props.sizeName &&
      css`
        font-size: ${props.sizeName};
      `}
  }
`;

export interface Props {
  id?: number;
  className?: string;
  empty?: keyof ThemeType["colors"];
  color?: keyof ThemeType["colors"];
  image?: IImage;
  isFallbackShown?: boolean;
  isIconShown?: boolean;
  isNameShown?: boolean;
  name?: string | undefined;
  /** Size of icon or size of [icon, name] */
  size?: number | [number, number];
  cy?: string;
}

function Chain({
  className,
  color,
  empty,
  isIconShown = true,
  isNameShown = true,
  isFallbackShown = false,
  size = 20,
  cy,
  ...props
}: Props) {
  const [sizeIcon, sizeName] = useMemo(() => {
    if (_.isArray(size)) {
      return [_.toNumber(_.get(size, "0")), _.toNumber(_.get(size, "1"))];
    }
    return [_.toNumber(size), undefined];
  }, [size]);

  const image = useMemo(() => {
    if (props.image) {
      return props.image;
    }

    if (!_.isNilOrEmptyString(props.id) && _.has(assets, props.id)) {
      return assets[props.id];
    }

    return undefined;
  }, [props.image, props.id]);

  const name = useMemo(() => {
    if (props.name) {
      return props.name;
    }

    if (!_.isNilOrEmptyString(props.id) && _.has(chains, props.id)) {
      return chains[props.id].name;
    }

    return "";
  }, [props.name, props.id]);

  return (
    <Wrapper
      className={className}
      color={color}
      sizeIcon={sizeIcon && _.toSuffix(sizeIcon, "px")}
      sizeName={sizeName && _.toSuffix(sizeName, "pt")}
      data-cy={cy}
    >
      {isIconShown && (
        <Icon
          purpose={image || isFallbackShown ? "image" : "empty"}
          value={image || isFallbackShown ? image ?? TokenUnknown : empty}
          size={sizeIcon}
        />
      )}
      {!_.isNilOrEmptyString(name) && isNameShown && (
        <Name>
          <p>{name}</p>
        </Name>
      )}
    </Wrapper>
  );
}

export default Chain;
