import { memo, useCallback, useState } from "react";
import styled from "styled-components";
import { PlusIcon } from "@heroicons/react/24/outline";
import { Button, Icon } from "@sablier/v2-components/molecules";
import { links } from "@sablier/v2-constants";
import { Locale, useT } from "@sablier/v2-locales";
import nextDynamic from "next/dynamic";
import { Description as DescriptionPartial } from "~/landing/components/atoms";
import { Section as SectionPartial } from "~/landing/components/organisms";
import { whys } from "~/landing/constants";
import type { PropsWithChildren } from "react";
import type { IWhy } from "~/landing/constants";
import Composition from "./Composition";

const Underlay = nextDynamic(() => import("./Underlay"), { ssr: false });

const WrapperPartial = styled.div`
  position: relative;
  width: 100%;
`;

const Content = styled.div`
  ${(props) => props.theme.styles.container}
  ${(props) => props.theme.styles.row}
  & {
    position: relative;
    z-index: ${(props) => props.theme.sizes.zIndexOver};
    align-items: flex-start;
    padding-top: calc(${(props) => props.theme.sizes.edge} * 2);
  }
`;

const Left = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    flex: 1;
    gap: calc(${(props) => props.theme.sizes.edge} * 4);
  }
`;

const Right = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    position: relative;
    width: 580px;
    height: 580px;
    margin-top: 60px;
  }
`;

const Section = styled(SectionPartial)`
  & > div {
    width: 100%;
    max-width: 100%;
    padding: 0 !important;
  }
`;

const List = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    gap: calc(${(props) => props.theme.sizes.edge} * 3);
    width: 100%;
    padding-top: calc(${(props) => props.theme.sizes.edge} * 2);
  }
`;

const Title = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    --size: 22px;
    --thickness: 2.2;
    gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
    color: ${(props) => props.theme.colors.white};
    & > p {
      font-weight: 600;
      font-size: 18pt;
      line-height: 140%;
    }
    & > div {
      padding-top: 2px;
    }
  }
`;

const Description = styled(DescriptionPartial)`
  p {
    white-space: pre-wrap;
  }
`;

const Container = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    gap: calc(${(props) => props.theme.sizes.edge} * 2);
    width: 100%;

    &:not([data-active="true"]) {
      cursor: pointer;
      &:hover,
      &:active {
        ${Title} {
          color: ${(props) => props.theme.colors.dark1000};
          span {
            color: ${(props) => props.theme.colors.dark800};
          }
        }
      }
      & > *:not(${Title}) {
        display: none;
      }

      ${Title} {
        color: ${(props) => props.theme.colors.dark700};
      }
    }
  }
`;

const Row = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    flex-wrap: wrap;
    gap: ${(props) => props.theme.sizes.edge};
    width: 100%;
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.minXL} {
    ${Description} {
      & > * {
        max-width: 500px;
      }
    }
  }
  ${(props) => props.theme.medias.maxLG} {
    ${Right} {
      width: 300px;
    }
  }

  ${(props) => props.theme.medias.maxMD} {
    ${Content} {
      padding-top: 0;
      ${Left} {
        gap: calc(${(props) => props.theme.sizes.edge} * 0);
        ${List} {
          gap: calc(${(props) => props.theme.sizes.edge} * 3 / 2);
          ${Container} {
            gap: calc(${(props) => props.theme.sizes.edge} * 1);
            & > * {
              display: inherit;
            }
            ${Title} {
              position: relative;
              margin-left: calc(${(props) => props.theme.sizes.edge} * 1);
              color: ${(props) => props.theme.colors.white};
              &:before {
                position: absolute;
                left: calc(${(props) => props.theme.sizes.edge} * -1);
                content: "";
                flex-shrink: 0;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: ${(props) =>
                  props.theme.colors.secondaryMiddle};
              }
              *:not(p) {
                display: none;
              }
            }
          }
        }
      }
      ${Right} {
        display: none;
      }
    }
    ${Row} {
      gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
    }
  }
  ${(props) => props.theme.medias.betweenMDAndLG} {
    ${Description} {
      & > * {
        max-width: 400px;
      }
    }
  }
  ${(props) => props.theme.medias.betweenSMAndMD} {
    ${List} {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
`;

interface PropsItem {
  id: IWhy;
  doView: (id: IWhy) => void;
  isActive: boolean;
}

const Item = memo(function Item({
  children,
  doView,
  id,
  isActive = false,
}: PropsWithChildren<PropsItem>) {
  const { t } = useT();
  const onClick = useCallback(() => {
    doView(id);
  }, [id, doView]);

  return (
    <Container data-active={isActive} onClick={onClick}>
      <Title>
        <p>{t(`landing.offering.sections.${id}.title`)}</p>
        <Icon value={PlusIcon} />
      </Title>
      <Description value={t(`landing.offering.sections.${id}.description`)} />
      {children}
    </Container>
  );
});

const actions: { title: Locale; to: string }[] = [
  {
    title: "landing.offering.sections.record.action.landing",
    to: links.v1.landing,
  },
  {
    title: "landing.offering.sections.record.action.app",
    to: links.v1.app,
  },
  {
    title: "landing.offering.sections.record.action.pay",
    to: links.v1.pay,
  },
  {
    title: "landing.offering.sections.record.action.safe",
    to: links.v1.safe,
  },
];

function Offering() {
  const { t } = useT();
  const [why, setWhy] = useState<IWhy>("flexibility");

  const doView = useCallback((id: IWhy) => {
    setWhy(id);
  }, []);

  return (
    <Wrapper id={"offering"}>
      <Content>
        <Left>
          <Section
            color={"secondaryMiddle"}
            title={t("landing.offering.title")}
            label={t("landing.offering.label")}
          />
          <List>
            {whys.map((id) => (
              <Item key={id} doView={doView} id={id} isActive={why === id} />
            ))}
            <Item doView={doView} id={"record"} isActive={why === "record"}>
              <Row>
                {actions.map((action) => (
                  <Button
                    accent={"secondaryNeon"}
                    appearance={"outline"}
                    key={action.title}
                    isMini
                    purpose={"external"}
                    title={t(action.title)}
                    to={action.to}
                  />
                ))}
              </Row>
            </Item>
          </List>
        </Left>
        <Right>
          <Composition id={why} />
        </Right>
      </Content>
      <Underlay />
    </Wrapper>
  );
}

export default Offering;
