import type { IImage } from "@sablier/v2-types";
import IconArbitrum from "./arbitrum.png";
import IconAvalanche from "./avalanche.png";
import IconBase from "./base.png";
import IconBlast from "./blast.png";
import IconBinanceSmartChain from "./bsc.png";
import IconTestnet from "./ethereum-gray.png";
import IconEthereum from "./ethereum-purple.png";
import IconGnosis from "./gnosis.png";
import IconIotex from "./iotex.png";
import IconLightlink from "./lightlink.png";
import IconLinea from "./linea.png";
import IconPolygon from "./matic.png";
import IconOptimism from "./optimism.png";
import IconScroll from "./scroll.png";
import IconZkSyncSepolia from "./zksync-sepolia.png";
import IconZkSync from "./zksync.png";

/**
 * Keep hardcoded values here because imports from @sablier/v2-constants
 * will expose this file to dependencies like lodash, which will
 * break the OG Meta Image generator (see client/pages/api).
 */

const CHAIN_ARBITRUM_ID = 42161;
const CHAIN_AVALANCHE_ID = 43114;
const CHAIN_BASE_ID = 8453;
const CHAIN_BLAST_ID = 81457;
const CHAIN_BSC_ID = 56;
const CHAIN_ETHEREUM_ID = 1;
const CHAIN_GNOSIS_ID = 100;
const CHAIN_IOTEX_ID = 4689;
const CHAIN_LIGHTLINK_ID = 1890;
const CHAIN_LINEA_ID = 59144;
const CHAIN_OPTIMISM_ID = 10;
const CHAIN_POLYGON_ID = 137;
const CHAIN_SCROLL_ID = 534352;
const CHAIN_SEPOLIA_ID = 11155111;
const CHAIN_ZKSYNC_ID = 324;
const CHAIN_ZKSYNC_SEPOLIA_ID = 300;

const chainAssets: Record<number, IImage> = {
  [CHAIN_ARBITRUM_ID]: IconArbitrum,
  [CHAIN_AVALANCHE_ID]: IconAvalanche,
  [CHAIN_BASE_ID]: IconBase,
  [CHAIN_BSC_ID]: IconBinanceSmartChain,
  [CHAIN_BLAST_ID]: IconBlast,
  [CHAIN_ETHEREUM_ID]: IconEthereum,
  [CHAIN_GNOSIS_ID]: IconGnosis,
  [CHAIN_IOTEX_ID]: IconIotex,
  [CHAIN_LINEA_ID]: IconLinea,
  [CHAIN_LIGHTLINK_ID]: IconLightlink,
  [CHAIN_OPTIMISM_ID]: IconOptimism,
  [CHAIN_POLYGON_ID]: IconPolygon,
  [CHAIN_SEPOLIA_ID]: IconTestnet,
  [CHAIN_SCROLL_ID]: IconScroll,
  [CHAIN_ZKSYNC_ID]: IconZkSync,
  [CHAIN_ZKSYNC_SEPOLIA_ID]: IconZkSyncSepolia,
};

export default chainAssets;
