import { useMemo } from "react";
import styled, { css, keyframes } from "styled-components";
import { ReactComponent as AssetDynamicOverlay } from "@sablier/v2-assets/illustrations/product-asset-dynamic-overlay.svg";
import AssetDynamic from "@sablier/v2-assets/illustrations/product-asset-dynamic.png";
import { ReactComponent as AssetGroupOverlay } from "@sablier/v2-assets/illustrations/product-asset-group-overlay.svg";
import AssetGroup from "@sablier/v2-assets/illustrations/product-asset-group.png";
import { ReactComponent as AssetNFTOverlay } from "@sablier/v2-assets/illustrations/product-asset-nft-overlay.svg";
import AssetNFT from "@sablier/v2-assets/illustrations/product-asset-nft.png";
import { ReactComponent as AssetPermissionlessOverlay } from "@sablier/v2-assets/illustrations/product-asset-permissionless-overlay.svg";
import AssetPermissionless from "@sablier/v2-assets/illustrations/product-asset-permissionless.png";
import { ReactComponent as AssetRecordOverlay } from "@sablier/v2-assets/illustrations/product-asset-record-overlay.svg";
import AssetRecord from "@sablier/v2-assets/illustrations/product-asset-record.png";
import { ReactComponent as AssetSecureOverlay } from "@sablier/v2-assets/illustrations/product-asset-secure-overlay.svg";
import AssetSecure from "@sablier/v2-assets/illustrations/product-asset-secure.png";
import Image from "next/image";
import { rgba } from "polished";
import type { IImage } from "@sablier/v2-types";
import type { FC } from "react";
import type { IWhy } from "~/landing/constants";

const dynamicKeyframes = keyframes`
  0%{
    stroke-dashoffset: 100%;
  }
  80%, 100%{
    stroke-dashoffset: -100%;
  }
`;

const groupKeyframes = keyframes`
  0%{
    stroke-dashoffset: -1;
  }
  70%, 100%{
    stroke-dashoffset: 1;
  }
`;
const nftKeyframes = keyframes`
  0%{
    stroke-dashoffset: -1;
  }
  100%{
    stroke-dashoffset: 1;
  }
`;

const permissionelssKeyframes = keyframes`
  0%{
    stroke-dashoffset: 100%;
  }
  80%{
    stroke-dashoffset: -50%;
  }
  100%{
    stroke-dashoffset: -100%;
  }
`;

const recordKeyframes = keyframes`
  0%{
    stroke-dashoffset: -1;
  }
  100%{
    stroke-dashoffset: 1;
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Sized = styled.div`
  width: 906px;
  height: 680px;
  & > svg {
    width: 906px;
    height: 680px;
  }
`;

const Content = styled(Sized)`
  ${(props) => props.theme.animations.fadeIn}
  & {
    position: absolute;
    left: 0;
    z-index: 30;
    width: 906px;
    height: 680px;

    img {
      object-fit: contain;
      object-position: left;
    }
  }
`;

const Overlay = styled(Sized)<{ id: IWhy }>`
  position: absolute;
  left: 0;
  z-index: 40;
  svg {
    path,
    circle {
      stroke: ${(props) => props.theme.colors.primaryMiddle};
      filter: drop-shadow(
        2px 2px 10px ${(props) => rgba(props.theme.colors.primaryEnd, 0.7)}
      );
      animation-name: ${dynamicKeyframes};
      animation-duration: 5000ms;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      animation-fill-mode: forwards;
      stroke-width: 3px;
      stroke-dasharray: 100%;
      stroke-linecap: round;

      ${(props) =>
        props.id === "group" &&
        css`
          stroke: ${(props) => props.theme.colors.secondaryMiddle};
          filter: drop-shadow(
            2px 2px 10px
              ${(props) => rgba(props.theme.colors.secondaryMiddle, 0.7)}
          );
          animation-name: ${groupKeyframes};
          animation-duration: 10000ms;
          stroke-dasharray: 1 1;
        `}

      ${(props) =>
        props.id === "nft" &&
        css`
          animation-name: ${nftKeyframes};
          stroke-dasharray: 1 1;
          animation-duration: 10000ms;
        `}

        ${(props) =>
        props.id === "permission" &&
        css`
          stroke: ${(props) => props.theme.colors.secondaryMiddle};
          filter: drop-shadow(
            2px 2px 10px
              ${(props) => rgba(props.theme.colors.secondaryMiddle, 0.4)}
          );
          animation-name: ${permissionelssKeyframes};
          animation-duration: 10000ms;
          animation-timing-function: ease-in-out;
          stroke-dasharray: 100%;
        `}


        ${(props) =>
        props.id === "record" &&
        css`
          stroke: ${(props) => props.theme.colors.secondaryMiddle};
          filter: drop-shadow(
            2px 2px 10px
              ${(props) => rgba(props.theme.colors.secondaryMiddle, 0.8)}
          );
          animation-name: ${recordKeyframes};
          animation-duration: 10000ms;
          stroke-dasharray: 1 1;
        `}

        ${(props) =>
        props.id === "audited" &&
        css`
          stroke: ${(props) => props.theme.colors.primaryMiddle};
          filter: drop-shadow(
            2px 2px 10px
              ${(props) => rgba(props.theme.colors.primaryMiddle, 0.4)}
          );
          animation-name: ${permissionelssKeyframes};
          animation-duration: 10000ms;
          animation-timing-function: ease-out;
          stroke-dasharray: 100%;
        `}
    }
  }
`;

type Whys = Record<
  IWhy,
  {
    asset: IImage;
    sizes?: string;
    quality?: number;
    Overlay?: FC;
    underlay?: unknown;
  }
>;

const whys: Whys = {
  flexibility: {
    asset: AssetDynamic,
    Overlay: AssetDynamicOverlay,
    quality: 100,
  },
  group: {
    asset: AssetGroup,
    Overlay: AssetGroupOverlay,
  },
  nft: {
    asset: AssetNFT,
    Overlay: AssetNFTOverlay,
  },
  permission: {
    asset: AssetPermissionless,
    Overlay: AssetPermissionlessOverlay,
    quality: 100,
  },
  audited: {
    asset: AssetSecure,
    Overlay: AssetSecureOverlay,
  },
  record: {
    asset: AssetRecord,
    Overlay: AssetRecordOverlay,
    quality: 85,
  },
};

function Composition({ id }: { id: IWhy }) {
  const element = useMemo(() => whys[id], [id]);

  return (
    <Wrapper>
      <Content key={id}>
        {element.Overlay ? (
          <Overlay id={id}>
            <element.Overlay />
          </Overlay>
        ) : (
          false
        )}
        <Image
          alt={"Sablier product illustration"}
          src={element.asset}
          fill
          sizes={
            element.sizes ??
            "(min-width: 700px) 35vw, (min-width: 1000px) 70vw, 0vw"
          }
          quality={element.quality ?? 95}
          priority={false}
        />
      </Content>
    </Wrapper>
  );
}

export default Composition;
