import { memo } from "react";
import styled from "styled-components";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import { ReactComponent as AssetLogo } from "@sablier/v2-assets/logo/icon-white.svg";
import { Button } from "@sablier/v2-components/molecules";
import { links } from "@sablier/v2-constants";
import { useT } from "@sablier/v2-locales";
import { Description } from "~/landing/components/atoms";
import { Toolbar } from "~/landing/components/organisms";
import Composition from "./Composition";
import Underlay from "./Underlay";

const WrapperPartial = styled.header`
  position: relative;
  width: 100%;
`;

const Content = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    position: relative;
    z-index: ${(props) => props.theme.sizes.zIndexOver};
    width: 100%;
  }
`;

const Container = styled.div`
  ${(props) => props.theme.styles.container}
  ${(props) => props.theme.styles.row}
  & {
    gap: ${(props) => props.theme.sizes.edge};
    justify-content: center;
    align-items: center;
    padding-top: calc(${(props) => props.theme.sizes.edge} * 2);
  }
`;

const Left = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    flex: 1;
    justify-content: center;
    align-items: flex-start;
  }
`;

const Right = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    flex: 1;
    justify-content: center;
    align-items: flex-start;
    height: 560px;
  }
`;

const Title = styled.div`
  margin-top: calc(${(props) => props.theme.sizes.edge} * 2);
  & > h1 {
    max-width: 500px;
    color: ${(props) => props.theme.colors.white};
    font-weight: 700;
    font-size: 52pt;
    line-height: 140%;
  }
`;

const Subtitle = styled(Description)`
  max-width: 500px;
  margin-top: calc(${(props) => props.theme.sizes.edge} * 3);
  margin-bottom: calc(${(props) => props.theme.sizes.edge} * 4);
  & > * {
    color: ${(props) => props.theme.colors.gray200};
  }
`;

const Actions = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: calc(${(props) => props.theme.sizes.edge} * 2);
`;

const Logo = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    justify-content: center;
    width: 16px;
    height: 24px;
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.minLG} {
    ${Actions} {
      & > *[data-component="button"] {
        height: auto;
        &:nth-child(1) {
          padding: calc(${(props) => props.theme.sizes.edge} * 5 / 4)
            calc(${(props) => props.theme.sizes.edge} * 3);
        }
      }
    }
  }

  ${(props) => props.theme.medias.betweenMDAndLG} {
    ${Actions} {
      grid-template-columns: 1fr 1fr;
      height: auto;
      *[data-component="button"] {
        height: auto;
        padding: calc(${(props) => props.theme.sizes.edge} * 1);
      }
    }
  }

  ${(props) => props.theme.medias.maxMD} {
    ${Container} {
      flex-direction: column;
      justify-content: center;
      height: auto;
      min-height: auto;
      padding-top: 0;
      padding-bottom: 0;
    }

    ${Left} {
      z-index: ${(props) => props.theme.sizes.zIndexOver};
      flex: none;
      gap: calc(${(props) => props.theme.sizes.edge} * 2);
      align-items: center;
      width: 100%;
      border-top: 2px solid ${(props) => props.theme.colors.dark250};
    }

    ${Right} {
      z-index: ${(props) => props.theme.sizes.zIndexUnder};
      flex: none;
      height: auto;
    }

    ${Title} {
      max-width: 100%;
      margin-top: calc(${(props) => props.theme.sizes.edge} * 2);
      text-align: center;
      & > * {
        max-width: 340px;
        font-size: 30pt;
      }
    }

    ${Subtitle} {
      max-width: 320px;
      margin: 0 auto;
      margin-bottom: calc(${(props) => props.theme.sizes.edge} * 1);
      text-align: center;
      & > * {
        font-size: 14pt;
      }
    }
  }
  ${(props) => props.theme.medias.maxSM} {
    ${Actions} {
      grid-template-columns: 1fr 1fr;
      gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
      & > *:nth-child(2) {
        background-color: ${(props) => props.theme.colors.dark150};
      }
    }
  }

  ${(props) => props.theme.medias.maxXS} {
    ${Actions} {
      grid-template-columns: 1fr;
      gap: calc(${(props) => props.theme.sizes.edge} * 1);
      margin: 0 auto;
    }
  }
`;

const ButtonLogo = memo(function ButtonLogo() {
  return (
    <Logo>
      <AssetLogo />
    </Logo>
  );
});

function Header() {
  const { t } = useT();
  return (
    <Wrapper id={"header"}>
      <Underlay />
      <Content>
        <Toolbar />
        <Container>
          <Left>
            <Title>
              <h1>{t("landing.home.title")}</h1>
            </Title>
            <Subtitle>
              <p>{t("landing.home.description")}</p>
            </Subtitle>
            <Actions>
              <Button
                accent={"primary"}
                appearance={"gradient"}
                purpose={"external"}
                leftPurpose={"custom"}
                left={ButtonLogo}
                title={t("structs.openApp")}
                to={links.v2.client}
              />
              <Button
                accent={"dark100"}
                appearance={"solid"}
                purpose={"external"}
                right={PaperAirplaneIcon}
                title={t("structs.scheduleCall")}
                to={links.forms.call}
              />
            </Actions>
          </Left>
          <Right>
            <Composition />
          </Right>
        </Container>
      </Content>
    </Wrapper>
  );
}

export default Header;
