import styled from "styled-components";
import { Button } from "@sablier/v2-components/molecules";
import { links } from "@sablier/v2-constants";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import nextDynamic from "next/dynamic";
import { Section as SectionPartial } from "~/landing/components/organisms";
import Composition from "./Composition";

const Underlay = nextDynamic(() => import("./Underlay"), { ssr: false });

const WrapperPartial = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    position: relative;
    align-items: center;
    width: 100%;
    margin-top: calc(${(props) => props.theme.sizes.edge} * 4);
  }
`;

const Above = styled.div`
  position: absolute;
  top: calc(${(props) => props.theme.sizes.edge} * -7 / 2);
`;

const New = styled(Button)`
  padding: 4px 6px !important;
  p {
    font-weight: 900 !important;
    font-size: 10pt !important;
  }
`;

const Section = styled(SectionPartial)`
  padding-bottom: calc(${(props) => props.theme.sizes.edge} * 2);

  & > div {
    justify-content: center;
    align-items: center;
  }
  div[data-component="top"] {
    align-items: center;
    width: 100%;
    * {
      align-items: center;
      max-width: 720px;
      text-align: center;
    }
  }
`;

const Wrapper = styled(WrapperPartial)``;

function Airstreams() {
  const { t } = useT();

  return (
    <Wrapper id={"airstreams"}>
      <Above>
        <New
          accent={"purple"}
          appearance={"gradient"}
          isMini
          isPreview
          isUnpadded
          title={_.toUpper(t("words.new"))}
        />
      </Above>
      <Section
        color={"white"}
        title={t("landing.airstreams.title")}
        label={t("landing.airstreams.label")}
        description={t("landing.airstreams.description")}
      >
        <Button
          accent={"purple"}
          appearance={"gradient"}
          title={t("structs.createAnAirstream")}
          titleShort={_.capitalize(t("structs.startNow"))}
          purpose={"external"}
          to={links.v2.clientAirstream}
        />
      </Section>
      <Composition />
      <Underlay />
    </Wrapper>
  );
}

export default Airstreams;
