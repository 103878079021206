import styled from "styled-components";
import AssetA16z from "@sablier/v2-assets/brands/a16zcrypto.png";
import AssetACapital from "@sablier/v2-assets/brands/acapital.png";
import AssetFactor from "@sablier/v2-assets/brands/factor.png";
import AssetGD1 from "@sablier/v2-assets/brands/gd1.png";
import { Emoji, External } from "@sablier/v2-components/atoms";
import { links } from "@sablier/v2-constants";
import { useT } from "@sablier/v2-locales";
import Image from "next/image";
import { Parallax } from "react-scroll-parallax";
import { Section as SectionPartial } from "~/landing/components/organisms";

const WrapperPartial = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: calc(${(props) => props.theme.sizes.edge} * 1);
`;

const Section = styled(SectionPartial)``;

const Card = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    position: relative;
    gap: calc(${(props) => props.theme.sizes.edge} * 1);
    width: 100%;
    border: 2px solid ${(props) => props.theme.colors.borderLanding};
    border-radius: 6px;
    background-color: ${(props) => props.theme.colors.dark00040};
    backdrop-filter: blur(10px);
  }
`;

const Playground = styled.div`
  position: absolute;
  top: -2px;
  width: 100%;
  height: 2px;
  overflow: hidden;

  &[data-anchor="bottom"] {
    top: initial;
    bottom: -2px;
  }
`;

const Shine = styled.div`
  ${(props) => props.theme.styles.shinny}
  & {
    --highlight: ${(props) => props.theme.colors.primary};
    --end: ${(props) => props.theme.colors.primaryTransparent};
    width: 300px;
  }
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: calc(${(props) => props.theme.sizes.edge} * 2);
  width: 100%;
  padding: calc(${(props) => props.theme.sizes.edge} * 2);
  padding-inline: calc(${(props) => props.theme.sizes.edge} * 3 / 2);
  border-radius: 4px;
  overflow: hidden;
`;

const Cell = styled(External)<{ size?: number }>`
  ${(props) => props.theme.styles.column}
  & {
    grid-column: span 1;
    position: relative;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;

    &:first-child,
    &:nth-child(2n + 1) {
      &:after {
        position: absolute;
        top: 0;
        left: -150px;
        content: "";
        z-index: ${(props) => props.theme.sizes.zIndexUnder};
        width: 300px;
        height: 300px;
        border-radius: 50%;
        background-color: ${(props) => props.theme.colors.dark200};

        filter: blur(100px);
      }
    }

    &:nth-child(3) {
      padding-bottom: 10px;
    }

    &:hover,
    &:active {
      img {
        opacity: 0.7;
      }
    }

    & > * {
      z-index: ${(props) => props.theme.sizes.zIndexOver};
    }

    a {
      &:hover,
      &:active {
        text-decoration: underline;
      }
    }
  }
`;

const Logo = styled.div`
  position: relative;
  width: 200px;
  height: 36px;

  img {
    object-fit: contain;
    object-position: bottom;
  }
`;

const Angel = styled.div`
  ${(props) => props.theme.styles.textElement}
  & {
    max-width: 200px;
    line-height: 1.6;
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxXL} {
    ${Row} {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  ${(props) => props.theme.medias.maxLG} {
    ${Section} {
      div[data-component="top"] {
        align-items: center;
        width: 100%;
        * {
          align-items: center;
          text-align: center;
        }
      }
    }
  }
  ${(props) => props.theme.medias.maxMD} {
    ${Row} {
      grid-template-columns: repeat(1, 1fr);
      gap: calc(${(props) => props.theme.sizes.edge} * 3);
      & > *:last-child {
        ${Angel} {
          max-width: 100%;
          text-align: center;
        }
      }
    }
  }

  ${(props) => props.theme.medias.maxSM} {
    ${Row} {
      grid-template-columns: repeat(1, 1fr);
      ${Logo} {
        width: 100%;
      }
    }
  }
`;

function Investors() {
  const { t } = useT();

  return (
    <Wrapper id={"investors"}>
      <Section
        color={"primaryMiddle"}
        label={t("landing.investors.label")}
        title={t("landing.investors.title")}
      >
        <Card>
          <Playground>
            <Parallax speed={-4} translateY={[0, 0]} translateX={[0, 50]}>
              <Shine />
            </Parallax>
          </Playground>
          <Playground data-anchor={"bottom"}>
            <Parallax speed={-4} translateY={[0, 0]} translateX={[55, 15]}>
              <Shine />
            </Parallax>
          </Playground>
          <Row>
            <Cell to={links.investor.a16zcrypto}>
              <Logo>
                <Image
                  alt={"a16zcrypto logo"}
                  fill
                  src={AssetA16z}
                  sizes={"100vw"}
                />
              </Logo>
            </Cell>
            <Cell to={links.investor.acapital}>
              <Logo>
                <Image
                  alt={"acapital logo"}
                  fill
                  src={AssetACapital}
                  sizes={"100vw"}
                />
              </Logo>
            </Cell>
            <Cell to={links.investor.factor}>
              <Logo>
                <Image
                  alt={"factor logo"}
                  fill
                  src={AssetFactor}
                  sizes={"100vw"}
                />
              </Logo>
            </Cell>
            <Cell to={links.investor.gd1}>
              <Logo>
                <Image alt={"GD1 logo"} fill src={AssetGD1} sizes={"100vw"} />
              </Logo>
            </Cell>
            <Cell as={"div"}>
              <Angel>
                <External to={links.investor.fenbushi}>Fenbushi</External>,{" "}
                <External to={links.investor.evanvanness}>
                  Evan Van Ness
                </External>
                , <External to={links.investor.litocoen}>Lito Coen</External>,{" "}
                <External to={links.investor.danielbar}>Daniel Bar</External>{" "}
                and more <Emoji symbol={"⭐"} />
              </Angel>
            </Cell>
          </Row>
        </Card>
      </Section>
    </Wrapper>
  );
}

export default Investors;
