import styled from "styled-components";
import { Icon } from "@sablier/v2-components/molecules";
import { rgba } from "polished";
import type { ThemeType } from "@sablier/v2-themes";
import type { ComponentProps } from "react";

const WrapperPartial = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    grid-column: span 1;
    row-gap: calc(${(props) => props.theme.sizes.edge} * 1);
    width: 100%;
    padding: calc(${(props) => props.theme.sizes.edge} * 1);
    border: 2px solid ${(props) => props.theme.colors.borderLanding};
    border-radius: 4px;
    background-color: ${(props) => rgba(props.theme.colors.dark000, 0.4)};
    backdrop-filter: blur(10px);
  }
`;

const Box = styled.div<{ color: keyof ThemeType["colors"] }>`
  --size: 22px;
  --thickness: 1.6;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  margin-bottom: calc(${(props) => props.theme.sizes.edge} * 1);
  color: ${(props) => props.theme.colors.white};
  border-radius: 4px;
  background-color: ${(props) => rgba(props.theme.colors.dark050, 0.05)};
  overflow: hidden;

  &:before {
    position: absolute;
    content: "";
    z-index: ${(props) => props.theme.sizes.zIndexUnder};
    width: 52px;
    height: 52px;
    margin-top: -70px;
    margin-right: -70px;
    background: ${(props) => props.theme.colors[props.color]};

    filter: blur(20px);
  }
  & > * {
    z-index: ${(props) => props.theme.sizes.zIndexOver};
  }
`;

const Title = styled.div`
  > p {
    ${(props) => props.theme.styles.textTitle}
    & {
      color: ${(props) => props.theme.colors.white};
    }
  }
`;

const Description = styled.div`
  & > p {
    ${(props) => props.theme.styles.textParagraph}
    & {
      color: ${(props) => props.theme.colors.gray300};
    }
  }
`;

const Wrapper = styled(WrapperPartial)``;

export interface Props {
  className?: string;
  color?: keyof ThemeType["colors"];
  description: string;
  icon: ComponentProps<typeof Icon>["value"];
  iconPurpose?: ComponentProps<typeof Icon>["purpose"];
  title: string;
}

function UseCase({
  className,
  color = "dark1000",
  description,
  icon,
  iconPurpose,
  title,
}: Props) {
  return (
    <Wrapper className={className}>
      <Box color={color}>
        <Icon purpose={iconPurpose} value={icon} />
      </Box>
      <Title>
        <p>{title}</p>
      </Title>
      <Description>
        <p>{description}</p>
      </Description>
    </Wrapper>
  );
}

export default UseCase;
