import styled from "styled-components";
import AssetGrid from "@sablier/v2-assets/elements/grid-pattern.svg";
import { Decor } from "@sablier/v2-components/atoms";

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${(props) => props.theme.sizes.zIndexUnder};
  width: 100%;
  height: 100%;
`;

const Mesh = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-image: url("${AssetGrid}");
  background-repeat: repeat;
  background-position: center;
  opacity: 0.04;
`;

const Fade = styled.div`
  position: absolute;
  left: 0;
  z-index: 15;
  width: 100%;
  height: 200px;
  background: linear-gradient(
    0deg,
    transparent,
    ${(props) => props.theme.colors.dark000}
  );
`;

const FadeTop = styled(Fade)`
  top: 0;
`;

const FadeBottom = styled(Fade)`
  bottom: 0;
  transform: rotate(180deg);
`;

const Background = styled(Decor.Background)`
  background-color: ${(props) => props.theme.colors.dark000} !important;
`;

function Underlay() {
  return (
    <Wrapper>
      <Background left={"top"} right={"top"}>
        <FadeTop />
        <FadeBottom />
        <Mesh />
      </Background>
    </Wrapper>
  );
}

export default Underlay;
