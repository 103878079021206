import styled from "styled-components";
import AssetExactly from "@sablier/v2-assets/brands/dark400/exactly.png";
import AssetFjord from "@sablier/v2-assets/brands/dark400/fjord.png";
import AssetImmutable from "@sablier/v2-assets/brands/dark400/immutable.png";
import AssetMaple from "@sablier/v2-assets/brands/dark400/maple.png";
import AssetUniswap from "@sablier/v2-assets/brands/dark400/uniswap.png";
import { Internal } from "@sablier/v2-components/atoms";
import { Label } from "@sablier/v2-components/molecules";
import { routes } from "@sablier/v2-constants";
import { Trans, useT } from "@sablier/v2-locales";
import Image from "next/image";

const WrapperPartial = styled.div`
  position: relative;
  z-index: ${(props) => props.theme.sizes.zIndexOver};
  width: 100%;
  padding-top: calc(${(props) => props.theme.sizes.edge} * 1);
`;

const Content = styled.div`
  ${(props) => props.theme.styles.container}
  ${(props) => props.theme.styles.row}
  & {
    justify-content: center;
    align-items: center;
    padding-bottom: calc(${(props) => props.theme.sizes.edge} * 2);
    border-bottom: 2px solid ${(props) => props.theme.colors.borderLanding};
  }
`;

const Left = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    position: relative;
    flex: 1;
  }
`;

const Note = styled.div`
  position: absolute;
  top: -50px;
  left: 0px;

  label {
    color: ${(props) => props.theme.colors.dark600};
  }
`;

const Right = styled.div`
  ${(props) => props.theme.styles.row}
`;

const Row = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    gap: calc(${(props) => props.theme.sizes.edge} * 2);
    width: 100%;
  }
`;

const Brand = styled(Internal)`
  position: relative;
  width: 140px;
  height: 32px;
  cursor: pointer;
  filter: none;
  transition: filter 100ms;

  &:hover {
    filter: brightness(0) invert(1);
    transition: filter 100ms;
  }
  img {
    z-index: 10;
    object-fit: contain;
  }
`;

const Data = styled.div`
  ${(props) => props.theme.styles.row}
  & > p {
    ${(props) => props.theme.styles.textSection}
    & {
      color: ${(props) => props.theme.colors.gray100};
      font-size: 18pt;
      span {
        color: ${(props) => props.theme.colors.gray400};
      }
    }
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxContainer} {
    ${Brand} {
      &:nth-child(5) {
        display: none;
      }
    }
    ${Row} {
      gap: calc(${(props) => props.theme.sizes.edge} * 1);
    }
  }
  ${(props) => props.theme.medias.maxLG} {
    padding-top: calc(${(props) => props.theme.sizes.edge} * 4);
    ${Content} {
      ${(props) => props.theme.styles.column}
      & {
        gap: calc(${(props) => props.theme.sizes.edge} * 3);
      }
    }
  }

  ${(props) => props.theme.medias.maxMD} {
    margin-top: calc(${(props) => props.theme.sizes.edge} * -4);
    padding-top: calc(${(props) => props.theme.sizes.edge} * 1);
    padding-bottom: calc(${(props) => props.theme.sizes.edge} * 1);
    ${Content} {
      ${(props) => props.theme.styles.column}
      & {
        gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
        padding-bottom: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
      }
    }

    ${Left} {
      width: 100%;
      ${Row} {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
        width: 100%;
      }
    }
    ${Right} {
      width: 100%;
      ${Row} {
        gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
        width: 100%;
      }
    }

    ${Brand} {
      ${(props) => props.theme.styles.row}
      & {
        grid-column: span 1;
        justify-content: center;
        width: 100%;
        height: 64px;
        padding: calc(${(props) => props.theme.sizes.edge} * 3 / 4);
        border: 2px solid ${(props) => props.theme.colors.borderLanding};
        border-radius: 2px;
        backdrop-filter: blur(15px);
        &:hover,
        &:active {
          filter: none;
        }

        img {
          position: relative !important;
          max-width: 100px;
          max-height: 26px;
          filter: brightness(0) invert(1);
          object-position: center;
        }
      }
    }
    ${Data} {
      flex: 1;
      justify-content: center;
      padding: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
      text-align: center;
      border: 2px solid ${(props) => props.theme.colors.borderLanding};
      border-radius: 2px;
      span {
        display: block;
      }
    }
  }
`;

const testimonials = `${routes.landing.pages.organizations.builder()}#who`;

function Brands() {
  const { t } = useT();

  return (
    <Wrapper id={"brands"}>
      <Content>
        <Left>
          <Note>
            <Label value={t("structs.usedBy")} />
          </Note>
          <Row>
            <Brand to={testimonials}>
              <Image
                alt={"Maple Finance logo"}
                src={AssetMaple}
                fill
                sizes={"(max-width: 300px) 40vw, 10vw"}
              />
            </Brand>
            <Brand to={testimonials}>
              <Image alt={"Fjord logo"} src={AssetFjord} fill sizes={"50vw"} />
            </Brand>
            <Brand to={testimonials}>
              <Image
                alt={"Immutable logo"}
                src={AssetImmutable}
                fill
                sizes={"50vw"}
              />
            </Brand>
            <Brand to={testimonials}>
              <Image
                alt={"Uniswap logo"}
                src={AssetUniswap}
                fill
                sizes={"80vw"}
              />
            </Brand>
            <Brand to={testimonials}>
              <Image
                alt={"Exactly logo"}
                src={AssetExactly}
                fill
                sizes={"80vw"}
              />
            </Brand>
          </Row>
        </Left>
        <Right>
          <Row>
            <Data>
              <p>
                <Trans
                  i18nKey={"landing.home.metrics.first"}
                  components={[<span key={0} />]}
                />
              </p>
            </Data>
            <Data>
              <p>
                <Trans
                  i18nKey={"landing.home.metrics.second"}
                  components={[<span key={0} />]}
                />
              </p>
            </Data>
          </Row>
        </Right>
      </Content>
    </Wrapper>
  );
}

export default Brands;
