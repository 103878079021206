import styled from "styled-components";
import {
  ArrowRightIcon,
  ArrowTrendingUpIcon,
  BanknotesIcon,
  GiftIcon,
  PlayCircleIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
import { links } from "@sablier/v2-constants";
import { useT } from "@sablier/v2-locales";
import nextDynamic from "next/dynamic";
import { UseCase } from "~/landing/components/molecules";
import { Section } from "~/landing/components/organisms";

const Underlay = nextDynamic(() => import("./Underlay"), { ssr: false });

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Collection = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: calc(${(props) => props.theme.sizes.edge} * 1);
  width: 100%;
  padding-top: calc(${(props) => props.theme.sizes.edge} * 3);

  ${(props) => props.theme.medias.maxLG} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${(props) => props.theme.medias.maxMD} {
    grid-template-columns: repeat(2, 1fr);
    gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
    padding-top: calc(${(props) => props.theme.sizes.edge} * 0);
  }

  ${(props) => props.theme.medias.maxXS} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

function Learn() {
  const { t } = useT();

  return (
    <Wrapper id={"learn"}>
      <Section
        actions={[
          {
            accent: "primaryMiddle",
            appearance: "transparent",
            isMini: false,

            purpose: "external",
            right: ArrowRightIcon,
            to: links.docs.v2Streaming,
            title: t("landing.learn.action"),
          },
          {
            accent: "gray100",
            appearance: "transparent",
            isMini: false,

            purpose: "external",
            right: PlayCircleIcon,
            to: links.video.intro,
            title: t("structs.watchIntro"),
          },
        ]}
        color={"primaryMiddle"}
        description={t("landing.learn.description")}
        title={t("landing.learn.title")}
        label={t("landing.learn.label")}
      >
        <Collection>
          <UseCase
            description={t("landing.learn.vesting.description")}
            icon={ArrowTrendingUpIcon}
            title={t("landing.learn.vesting.title")}
          />
          <UseCase
            description={t("landing.learn.payroll.description")}
            icon={BanknotesIcon}
            title={t("landing.learn.payroll.title")}
          />
          <UseCase
            description={t("landing.learn.airdrops.description")}
            icon={SparklesIcon}
            title={t("landing.learn.airdrops.title")}
          />
          <UseCase
            description={t("landing.learn.grants.description")}
            icon={GiftIcon}
            title={t("landing.learn.grants.title")}
          />
        </Collection>
      </Section>
      <Underlay />
    </Wrapper>
  );
}

export default Learn;
