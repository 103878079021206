import styled from "styled-components";
import { Meta } from "@sablier/v2-components/organisms";
import { links } from "@sablier/v2-constants";
import { useT } from "@sablier/v2-locales";
import { Audit, Footer, Pricing } from "~/landing/components/organisms";
import { tags } from "~/landing/constants";
import Airstreams from "./Airstreams";
import Brands from "./Brands";
import Develop from "./Develop";
import Header from "./Header";
import Investors from "./Investors";
import Learn from "./Learn";
import Offering from "./Offering";
import Ready from "./Ready";

const WrapperPartial = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.dark000};
`;

const Divider = styled.div`
  z-index: ${(props) => props.theme.sizes.zIndexOver};
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.colors.border};
`;

const Content = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    position: relative;
    gap: calc(${(props) => props.theme.sizes.edge} * 8);
    width: 100%;

    & > :nth-child(5) {
      z-index: 1;
    }
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxLG} {
    ${Content} {
      gap: calc(${(props) => props.theme.sizes.edge} * 2);
    }
  }
`;

function Home() {
  const { t } = useT();

  return (
    <Wrapper data-availability={tags.pageRendered}>
      <Meta.Head
        canonical={() => links.v2.landing}
        description={t("about.meta.landing.description")}
        title={t("about.meta.landing.titleHome")}
        purpose={"landing"}
        domain={links.v2.landing}
      />
      <Content>
        <Header />
        <Brands />
        <Learn />
        <Offering />
        <Develop />
        <Audit />
        <Investors />
        <Airstreams />
        <Ready />
        <Divider />
        <Pricing />
        <Footer />
      </Content>
    </Wrapper>
  );
}

export default Home;
