import styled, { keyframes } from "styled-components";
import AssetClock from "@sablier/v2-assets/3d/clock-blue.png";
import AssetETH from "@sablier/v2-assets/3d/coin-eth-blue.png";
import AssetUSDC from "@sablier/v2-assets/3d/coin-usdc-blue.png";
import AssetHourglass from "@sablier/v2-assets/3d/hourglass-d1-blue.png";
import AssetWallet from "@sablier/v2-assets/3d/wallet-blue.png";
import Image from "next/image";
import { rgba } from "polished";

const WrapperPartial = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    position: relative;
    justify-content: center;
    width: 100%;
    user-select: none;
  }
`;

const Container = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    position: absolute;
    justify-content: center;
    width: 540px;
    height: 540px;
    &:before {
      --from: ${(props) => props.theme.colors.dark000};
      --to: ${(props) => props.theme.colors.dark200};
      position: absolute;
      content: "";
      width: 500px;
      height: 500px;
      filter: blur(80px);
    }
  }
`;

const topDownKeyframes = keyframes`
  0%{
    transform: translate3D(0px, 0px, 0px);
  }
  50%{
    transform: translate3D(0px, -14px, 0px);
  }
  100%{
    transform: translate3D(0px, 0px, 0px);

  }
`;

const leftRightKeyframes = keyframes`
  0%{
    transform: translate3D(0px, 0px, 0px);
  }
  50%{
    transform: translate3D(20px, -10px, 0px);
  }
  100%{
    transform: translate3D(0px, 0px, 0px);

  }
`;

const rightLeftKeyframes = keyframes`
  0%{
    transform: translate3D(0px, 0px, 0px);
  }
  50%{
    transform: translate3D(-20px, 10px, 0px);
  }
  100%{
    transform: translate3D(0px, 0px, 0px);
  }
`;

const Item = styled.div`
  position: absolute;
  width: 120px;
  height: 120px;
  color: ${(props) => rgba(props.theme.colors.secondaryStart, 0.2)};
  will-change: transform;
  animation-name: ${leftRightKeyframes};
  animation-duration: 8000ms;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  img {
    object-fit: contain;
  }
`;

const Wallet = styled(Item)`
  position: absolute;
  top: -20px;
  right: -20px;
  width: 160px;
  height: 160px;
`;

const ETH = styled(Item)`
  bottom: -40px;
  right: -20px;
  animation-name: ${rightLeftKeyframes};
`;

const USDC = styled(Item)`
  top: -20px;
  left: -40px;
  animation-name: ${rightLeftKeyframes};
`;

const Clock = styled(Item)`
  left: -20px;
  bottom: -40px;
`;

const Hourglass = styled(Item)`
  position: absolute;
  width: 440px;
  height: 440px;
  animation-name: ${topDownKeyframes};
  animation-duration: 6000ms;

  img {
    object-fit: contain;
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.betweenMDAndLG} {
    transform: translateX(80px);
  }

  ${(props) => props.theme.medias.maxMD} {
    height: 520px;
    ${Container} {
      width: 300px;
      height: 300px;
      ${Item} {
        width: 90px;
        height: 90px;
        animation-duration: 14000ms;
      }

      ${Hourglass} {
        width: 340px;
        height: 340px;
        & > * {
          transform: rotate(-30deg);
        }
      }

      ${Wallet} {
        top: -40px;
        right: -30px;
        width: 110px;
        height: 110px;
      }
      ${USDC} {
        top: -140px;
        left: -30px;
      }
      ${Clock} {
        left: -60px;
        bottom: -10px;
      }
      ${ETH} {
        bottom: -110px;
        right: -40px;
      }
    }
  }

  ${(props) => props.theme.medias.betweenSMAndMD} {
    & > ${Container} {
      ${Item} {
        width: 120px;
        height: 120px;
        animation-duration: 14000ms;
      }
      ${Hourglass} {
        width: 380px;
        height: 380px;
      }
      ${Wallet} {
        right: -110px;
      }
      ${USDC} {
        left: -160px;
      }
      ${Clock} {
        left: -140px;
      }
      ${ETH} {
        right: -120px;
      }
    }
  }
`;

function Composition() {
  return (
    <Wrapper>
      <Container>
        <Hourglass>
          <Image
            alt={"Hourglass"}
            priority={true}
            src={AssetHourglass}
            fill
            sizes={"(min-width: 1000px) 40vw, 80vw"}
          />
        </Hourglass>
        <Wallet>
          <Image
            alt={"Wallet"}
            priority={true}
            src={AssetWallet}
            fill
            sizes={"(min-width: 1000px) 10vw, 20vw"}
          />
        </Wallet>
        <ETH>
          <Image
            alt={"Ethereum"}
            priority={true}
            src={AssetETH}
            fill
            sizes={"(min-width: 1000px) 10vw, 20vw"}
          />
        </ETH>
        <USDC>
          <Image
            alt={"USDC Coin"}
            priority={true}
            src={AssetUSDC}
            fill
            sizes={"(min-width: 1000px) 10vw, 20vw"}
          />
        </USDC>
        <Clock>
          <Image
            alt={"Clock"}
            priority={true}
            src={AssetClock}
            fill
            sizes={"(min-width: 1000px) 10vw, 20vw"}
          />
        </Clock>
      </Container>
    </Wrapper>
  );
}

export default Composition;
