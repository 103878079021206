import styled from "styled-components";
import {
  ArrowRightIcon,
  BeakerIcon,
  BriefcaseIcon,
  ChevronRightIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import { links, routes } from "@sablier/v2-constants";
import { useT } from "@sablier/v2-locales";
import { CallToAction as Base } from "~/landing/components/organisms";

const Wrapper = styled.div`
  width: 100%;
`;

const CallToAction = styled(Base)``;

function Ready() {
  const { t } = useT();

  return (
    <Wrapper id={"ready"}>
      <CallToAction
        actions={[
          {
            accent: "primary",
            appearance: "gradient",
            isMini: true,
            purpose: "external",
            right: ArrowRightIcon,
            to: links.v2.client,
            title: t("structs.openApp"),
          },
          {
            accent: "dark200",
            appearance: "solid",
            isMini: true,
            purpose: "external",
            right: BeakerIcon,

            to: links.v2.clientDemo,
            title: t("structs.seeDemo"),
          },
        ]}
        description={t("landing.ready.description")}
        items={[
          {
            action: {
              accent: "primaryMiddle",
              appearance: "transparent",
              isMini: true,
              right: ChevronRightIcon,
              title: t("structs.learnMore"),
              purpose: "internal",
              to: routes.landing.pages.organizations.builder(),
            },
            description: t("landing.ready.organization.description"),
            icon: UserGroupIcon,
            title: t("landing.ready.organization.title"),
          },
          {
            action: {
              accent: "primaryMiddle",
              appearance: "transparent",
              isMini: true,
              purpose: "external",
              right: ChevronRightIcon,
              title: t("structs.scheduleCall"),
              to: links.forms.call,
            },
            description: t("landing.ready.sales.description"),
            icon: BriefcaseIcon,
            title: t("landing.ready.sales.title"),
          },
        ]}
        label={t("landing.ready.label")}
        title={t("landing.ready.title")}
      />
    </Wrapper>
  );
}

export default Ready;
