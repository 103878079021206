import styled, { keyframes } from "styled-components";
import AssetGlobe from "@sablier/v2-assets/3d/globe-purple-bright.png";
import Image from "next/image";
import { rgba } from "polished";
import { Parallax } from "react-scroll-parallax";

const WrapperPartial = styled.div`
  position: relative;
  z-index: ${(props) => props.theme.sizes.zIndexOver};
  width: 100%;
`;

const Container = styled.div`
  ${(props) => props.theme.styles.container}
  ${(props) => props.theme.styles.column}
  & {
    position: relative;
    justify-content: center;
    align-items: center;
    height: 460px;
    margin-top: calc(${(props) => props.theme.sizes.edge} * 6);
  }
`;

const travel = keyframes`
  0%{
    transform: translateX(0);
  }

  100%{
    transform: translateX(calc(180px + 16px));
  }
`;

const Cards = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    position: absolute;
    z-index: ${(props) => props.theme.sizes.zIndexUnder};
    gap: ${(props) => props.theme.sizes.edge};
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

const Card = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    position: relative;
    z-index: ${(props) => props.theme.sizes.zIndexOver};
    flex-shrink: 0;
    width: 180px;
    height: 180px;
    border: 2px solid ${(props) => props.theme.colors.purple10};
    border-radius: 8px;
    background-color: ${(props) => rgba(props.theme.colors.dark050, 0.6)};
    backdrop-filter: blur(12px);

    transform-origin: center;
    overflow: hidden;

    will-change: opacity, transform, width;
    animation-name: ${travel};
    animation-duration: 6000ms;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;

    &:before {
      position: absolute;
      content: "";
      z-index: ${(props) => props.theme.sizes.zIndexUnder};
      width: 80px;
      height: 80px;
      margin-top: -50px;
      margin-right: -50px;
      background: radial-gradient(
        circle at center,
        ${(props) => props.theme.colors.purple} -100%,
        ${(props) => props.theme.colors.purple} 70%,
        ${(props) => props.theme.colors.purpleTransparent} 70%
      );
      opacity: 1;
      filter: blur(100px);
    }

    & > * {
      z-index: ${(props) => props.theme.sizes.zIndexOver};
    }
  }
`;

const Globes = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    position: absolute;
    z-index: ${(props) => props.theme.sizes.zIndexOver};
    gap: 32px;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 460px;
    perspective: 1px;
  }
`;

const Globe_1 = styled.div`
  position: relative;
  width: 280px;
  height: 280px;

  img {
    object-fit: contain;
  }
`;

const Globe_2 = styled.div`
  position: absolute;
  top: -30px;
  width: 110px;
  height: 110px;
  margin-right: -460px;

  img {
    object-fit: contain;
  }
`;

const Globe_3 = styled.div`
  position: absolute;
  top: -60px;
  width: 80px;
  height: 80px;
  margin-left: -340px;
  filter: blur(4px);

  img {
    object-fit: contain;
  }
`;

const Globe_4 = styled.div`
  position: absolute;
  left: 0;
  bottom: -90px;
  z-index: ${(props) => props.theme.sizes.zIndexUnder};
  width: 140px;
  height: 140px;
  margin-left: -540px;
  img {
    object-fit: contain;
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxSM} {
    ${Container} {
      height: 280px;
      ${Globe_1} {
        width: 220px;
        height: 220px;
      }
      ${Globe_3} {
        top: 10px;
      }
      ${Globe_4} {
        display: none;
      }
      ${Card} {
        width: 160px;
        height: 160px;
      }
    }
  }
  ${(props) => props.theme.medias.maxXS} {
    ${Globe_2} {
      top: -20px;
      margin-right: -280px;
    }
  }
`;

function Composition() {
  return (
    <Wrapper>
      <Container>
        <Globes>
          <Parallax speed={-5}>
            <Globe_1>
              <Image
                alt={"Airstream Item"}
                src={AssetGlobe}
                fill
                sizes={"(min-width: 1000px) 20vw, 60vw"}
              />
            </Globe_1>
          </Parallax>

          <Globe_2>
            <Image
              alt={"Airstream Item"}
              src={AssetGlobe}
              fill
              sizes={"(min-width: 1000px) 5vw, 20vw"}
            />
          </Globe_2>

          <Globe_3>
            <Image
              alt={"Airstream Item"}
              src={AssetGlobe}
              fill
              sizes={"(min-width: 1000px) 5vw, 20vw"}
            />
          </Globe_3>
        </Globes>
        <Cards>
          {[...new Array(12).keys()].map((index) => (
            <Card key={index} />
          ))}
        </Cards>
        <Parallax speed={5}>
          <Globe_4>
            <Image
              alt={"Airstream Item"}
              src={AssetGlobe}
              fill
              sizes={"(min-width: 1000px) 30vw, 20vw"}
            />
          </Globe_4>
        </Parallax>
      </Container>
    </Wrapper>
  );
}

export default Composition;
